<template>
  <div class="export-container">
    <v-btn class="btn-create btn-export mr-4" @click="onExportExcel()">
      <span><i class="fal fa-file-export" style="margin-right: 4px;"></i></span>Export Excel
    </v-btn>
    <div class="section-content-table">
      <table ref="employeeExport" class="table table-view table-salary">
        <thead class="table-salary">
        <tr>
          <th class="text-left table-nowrap">
            Gender
          </th>
          <th class="text-left table-nowrap">
            Name
          </th>
          <th class="text-left table-nowrap">
            Surname
          </th>
          <th class="text-left table-nowrap">
            Email
          </th>
          <th class="text-left table-nowrap">
            Birthday
          </th>
          <th class="text-left table-nowrap">
            Nationality
          </th>
          <th class="text-left table-nowrap">
            Province
          </th>
          <th class="text-left table-nowrap">
            District
          </th>
          <th class="text-left table-nowrap">
            Village
          </th>
          <th class="text-left table-nowrap">
            Bank Name
          </th>
          <th class="text-left table-nowrap">
            Bank Account Name
          </th>
          <th class="text-left table-nowrap">
            Bank Account
          </th>
          <th class="text-left table-nowrap">
            Position
          </th>
          <th class="text-left table-nowrap">
            Employee Level
          </th>
          <th class="text-left table-nowrap">
            Branch Name
          </th>
          <!--          <th class="text-left table-nowrap" v-for="(item,index) in employees">-->
          <!--            <span v-for="(holiday,index) in item.joinHolidays">-->
          <!--                <pre>{{holiday.name}}</pre>-->
          <!--            </span>-->
          <!--          </th>-->
          <th class="text-left table-nowrap" v-for="(item,index) in holiday">
            {{ item.name }}
          </th>
          <th class="text-left table-nowrap">
            Status
          </th>
        </tr>
        </thead>


        <tbody>
        <tr v-for="(item,idx) in employees" :key="idx">
          <td class="table-nowrap">{{ item.gender }}</td>
          <td class="table-nowrap">{{ item.name }}</td>
          <td class="table-nowrap">{{ item.surname }}</td>
          <td class="table-nowrap">{{ item.email }}</td>
          <td class="table-nowrap">{{ item.birth_date }}</td>
          <td class="table-nowrap">{{ item.nationality }}</td>
          <td class="table-nowrap">{{ item.province_name }}</td>
          <td class="table-nowrap">{{ item.district_name }}</td>
          <td class="table-nowrap">{{ item.village_name }}</td>
          <td class="table-nowrap">{{ item.bank_name }}</td>
          <td class="table-nowrap">{{ item.bank_account_name }}</td>
          <td class="table-nowrap">{{ item.bank_account }}</td>
          <td class="d-flex flex-column table-nowrap" v-for="(position) in item.joinPositions ">
            {{ position.name }}
          </td>
          <td class="table-nowrap">{{ item.level_name }}</td>
          <td class="table-nowrap">{{ item.branch_name }}</td>
          <td class="table-nowrap">
            <div v-for="(item,index) in item.joinHolidays" :key="index">
              <div class="d-flex flex-row table-nowrap">{{ item.name }}{{ item.amount }} ວັນ,</div>
            </div>
          </td>
          <td>
            <div>
              <span class="fullTime" v-if="item.employment_type === 'FullTime'">{{ item.employment_type }}</span>
              <span class="partTime" v-else>{{ item.employment_type }}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import XLSX from "xlsx";
import _ from "lodash"

export default {
  data() {
    return {
      employees: []
    }
  },
  methods: {
    // onExportExcel() {
    //   let empRef = this.$refs.employeeExport;
    //   let siteInfo = XLSX.utils.table_to_book(empRef);
    //   XLSX.writeFile(siteInfo, "employee.xlsx")
    // },


    fetchEmployee() {
      this.$axios.get(`company/list-employee`).then((res) => {
        if (res.status === 200) {
          this.employees = res.data.data.data;
        }
      })
    }
  },
  computed: {
    holiday() {
      return this.employees.map((item) => _.find(item.joinHolidays))
    }
  },
  created() {
    this.fetchEmployee();
  }
}
</script>

<style scoped lang="scss">
.export-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .section-content-table {
    max-width: 1200px;
    height: auto;
    overflow: auto;
    padding: 20px;
  }
}

.btn-create {
  border: none;
  outline: none;
  height: 40px;
  animation-delay: 0.1s;
  background-color: $main-btn-color;
  color: #FFFFFF !important;
  text-transform: capitalize;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  font-family: $font-family;
  letter-spacing: 1px;
}

.btn-export {
  background: #217346 !important;
}

.table-nowrap {
  white-space: nowrap;
}
</style>
